import { environment } from "@e-tenant-hub/environments";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let require: any;

export class AppConfig {
	apiRoot = "";
	authApiRoot = "";
	appCompanyName = "";
	appDisplayName = "";
	appName = "";
	appVersion = "";
	appVersionBuild = "";
	appVersionName = "";
	authority = "";
	clientId = "";
	clientSecret = "";
	enableAdminSolicitationReading = false;
	enableIntegrationMode = false;
	hostHub = "";
	production = false;
	sumUpAffiliateKey = "";
	sumUpCallbackPath = "";
	sumUpSchemeUrl = "";
	systemPayPaymentUrl = "";
	useRopcFlow = false;

	constructor(init?: Partial<AppConfig>) {
		Object.assign(this, init);
	}

	static createAppConfigFromEnvironment(): AppConfig {
		return new AppConfig({
			apiRoot: environment.apiRoot,
			authApiRoot: environment.authApiRoot,
			appDisplayName: environment.appDisplayName,
			appName: environment.appName,
			appVersion: environment.appVersion,
			appVersionBuild: require("../../../../../../package.json").version,
			appVersionName: environment.appVersionName,
			authority: environment.authority,
			clientId: environment.clientId,
			clientSecret: environment.clientSecret,
			enableAdminSolicitationReading: JSON.parse(environment.enableAdminSolicitationReading),
			enableIntegrationMode: JSON.parse(environment.enableIntegrationMode),
			hostHub: environment.hostHub,
			production: environment.production,
			sumUpAffiliateKey: environment.sumUpAffiliateKey,
			sumUpCallbackPath: environment.sumUpCallbackPath,
			sumUpSchemeUrl: environment.sumUpSchemeUrl,
			systemPayPaymentUrl: environment.systemPayPaymentUrl,
			useRopcFlow: JSON.parse(environment.useRopcFlow),
		});
	}
}
