import { AppConfig } from "@e-tenant-hub/shared/config";
import { AuthConfig } from "angular-oauth2-oidc";

export class ROPCAuthConfig extends AuthConfig {
	constructor(appConfig: AppConfig, isProduction: boolean) {
		super();
		this.issuer = `${appConfig.authority}`;
		this.redirectUri = `${window.location.origin}/signin-callback`;
		this.oidc = false;
		this.scope = "openid emh-tenant-api emh-auth-api offline_access";

		this.clientId = appConfig.clientId;
		this.dummyClientSecret = appConfig.clientSecret;
		this.showDebugInformation = !isProduction;
	}
}
